import $ from "jquery";

window.$ = window.jQuery = $;

$(document).ready(function () {
    console.log("%c\r\n    __                               __  \r\n   / /_  ____  ___  ________   _____/ /_ \r\n  / __ \\/ __ \\/ _ \\/ ___/ _ \\ / ___/ __ \\\r\n / /_/ / /_/ /  __(__  )  __// /__/ / / /\r\n/_.___/\\____/\\___/____/\\___(_)___/_/ /_/ \r\n                                         \r\n", "color:cyan");

    let hasSeenHolidayInfo = localStorage.getItem('hasSeenHolidayInfo');
    if (!hasSeenHolidayInfo) {
        $('#holiday-info').addClass('opened');

        $('#close-holiday-info').click(function (e) {
            localStorage.setItem('hasSeenHolidayInfo', 'true');
            $('#holiday-info').removeClass('opened');
        })
    }


    $('#materialAcceptFilter').click(function (e) {
        let ids = [];
        $('.filter[data-active="true"]').each(function (i, e) {
            ids.push($(this).attr('data-id'));
        })

        window.location.href =
            updateURLParameter(updateURLParameter(updateURLParameter(window.location.href, 'filters', ids.join(',')), 'q', $('#searchField').val()), 'page', '1');
    });
    $('#materialResetFilter').click(function (e) {
        window.location.href =
            updateURLParameter(updateURLParameter(updateURLParameter(window.location.href, 'filters', ''), 'q', ''), 'page', '1');
    });
    $('#searchField').keydown(function (e) {
        let ids = [];
        if (e.which === 13) {

            $('.filter[data-active="true"]').each(function (i, e) {
                ids.push($(this).attr('data-id'));
            })

            window.location.href =
                updateURLParameter(updateURLParameter(window.location.href, 'filters', ids.join(',')), 'q', $('#searchField').val())
        }
    })

    $('#uniqueAcceptFilter').click(function (e) {
        window.location.href = getCurrentFiltersAsUrl();
    })

    $('#uniqueResetFilter').click(function (e) {
        let newUrl = location.href;

        newUrl = removeURLParameter(newUrl, 'priceMin');
        newUrl = removeURLParameter(newUrl, 'priceMax');
        newUrl = removeURLParameter(newUrl, 'heightMin');
        newUrl = removeURLParameter(newUrl, 'heightMax');
        newUrl = removeURLParameter(newUrl, 'widthMin');
        newUrl = removeURLParameter(newUrl, 'widthMax');
        newUrl = removeURLParameter(newUrl, 'thicknessMin');
        newUrl = removeURLParameter(newUrl, 'thicknessMax');
        newUrl = removeURLParameter(newUrl, 'materials');

        window.location.href = newUrl;
    });

    $('.selectGesteinsart').click(function (e) {
        let newUrl = getCurrentFiltersAsUrl();

        newUrl = removeURLParameter(newUrl, 'material');

        window.location.href = updateURLParameter(newUrl, 'gesteinsart', $(this).attr('data-id'));
    });

    $('.selectMaterial').click(function (e) {
        let newUrl = getCurrentFiltersAsUrl();

        newUrl = removeURLParameter(newUrl, 'gesteinsart');

        window.location.href = updateURLParameter(newUrl, 'material', $(this).attr('data-id'));
    });

    $('#contactPrivate').click(function (e) {
        $(this).removeClass('rawButton');
        $('#contactCompany').addClass('rawButton')
        $('#companyInput').addClass('d-none')
    });

    $('#contactCompany').click(function (e) {
        $(this).removeClass('rawButton');
        $('#contactPrivate').addClass('rawButton')
        $('#companyInput').removeClass('d-none')
    });



    function getCurrentFiltersAsUrl() {
        let newUrl = location.href;

        if ($('#filter-price').length != 0) {
            let priceMin = $('#filter-price').find('.sri1')[0].innerText ?? 0;
            let priceMax = $('#filter-price').find('.sri2')[0].innerText ?? 0;

            newUrl = updateURLParameter(newUrl, 'priceMin', priceMin);
            newUrl = updateURLParameter(newUrl, 'priceMax', priceMax);
        }

        let materials = [];
        if ($('.uniqueFilterMaterial').length > 0) {
            $('.uniqueFilterMaterial:checkbox:checked').each(function (e, i) {
                materials.push($(this).attr('data-id'));
            });

            if (materials.length > 0) {
                newUrl = removeURLParameter(newUrl, 'materials');
                newUrl = updateURLParameter(newUrl, 'materials', materials.join(','))
            }
        }
        console.log(materials);

        let heightMin = $('#filter-height').find('.sri1')[0].innerText ?? 0;
        let heightMax = $('#filter-height').find('.sri2')[0].innerText ?? 0;

        let widthMin = $('#filter-width').find('.sri1')[0].innerText ?? 0;
        let widthMax = $('#filter-width').find('.sri2')[0].innerText ?? 0;

        let thicknessMin = $('#filter-thickness').find('.sri1')[0].innerText ?? 0;
        let thicknessMax = $('#filter-thickness').find('.sri2')[0].innerText ?? 0;

        newUrl = updateURLParameter(newUrl, 'heightMin', heightMin);
        newUrl = updateURLParameter(newUrl, 'heightMax', heightMax);
        newUrl = updateURLParameter(newUrl, 'widthMin', widthMin);
        newUrl = updateURLParameter(newUrl, 'widthMax', widthMax);
        newUrl = updateURLParameter(newUrl, 'thicknessMin', thicknessMin);
        newUrl = updateURLParameter(newUrl, 'thicknessMax', thicknessMax);

        return newUrl;
    }

    $(document).on('click', '.filter', function () {
        if ($(this).children().hasClass('selectedColorFilter')) {
            $(this).attr('data-active', false);
            $(this).children().removeClass('selectedColorFilter')
        } else {
            $(this).attr('data-active', true);
            $(this).children().addClass('selectedColorFilter')
        }
    });
    $(document).on('change', 'input.filter', function () {
        if ($(this).prop('checked')) {
            console.log('testA')
            $(this).attr('data-active', true);
        } else {
            console.log('testB')
            $(this).attr('data-active', false);

        }
    })

    $('.uniqueFilterMaterial').on('change', function () {
        $('#uniqueAcceptFilter').trigger('click');
    })
});


function updateURLParameter(url, param, paramVal) {
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] != param) {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}
